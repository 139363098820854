
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import LastSync from '@/components/LastSync.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import StockListFilter from '@/components/reports-v2/components/filters/StockListFilter.vue';
import AgentsListFilter from '@/components/reports-v2/components/filters/AgentsListFilter.vue';
import SupplierListFilter from '@/components/reports-v2/components/filters/SupplierListFilter.vue';
// tslint:disable:max-line-length
import SupplierPaymentAnalysisBarChart from '../../components/codedWidgets/supplier/SupplierPaymentAnalysisBarChart.vue';
import SupplierAnalysisTreeMap from '../../components/codedWidgets/supplier/SupplierAnalysisTreeMap.vue';
import DocumentAnalysisBarChart from '../../components/codedWidgets/supplier/DocumentAnalysisBarChart.vue';

@Component({
	components: {
		StickyFilterContainer,
		LastSync,
		SelectFilter,
		StockListFilter,
		AgentsListFilter,
		SupplierListFilter,
		SupplierPaymentAnalysisBarChart,
		SupplierAnalysisTreeMap,
		DocumentAnalysisBarChart,
		DateRangeFilter,
	},
})
export default class SupplierOverview extends Vue {
	public selectedAgents: string[] = [];
	public selectedSuppliers: string[] = [];
	public selectedAsOfDate: number = moment().startOf('day').valueOf();
	public selectedDateRange: [number, number] = [
		moment().startOf('year').valueOf(),
		moment().endOf('day').valueOf(),
	];

	public onDateAsOf(value: number) {
		this.selectedAsOfDate = value;
	}

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
