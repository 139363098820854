
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseFilter from './BaseFilter.vue';
import Dropdown from 'primevue/dropdown';

@Component({
	components: { Dropdown },
})
export default class SelectFilter extends BaseFilter {
	@Prop({ default: () => [] }) public options!: string[];
	@Prop({ default: '' }) public value!: string;
	@Prop({ default: 'Please select' }) public firstOption!: string;
	@Prop({ default: 'text' }) public optionLabel!: string | null;
	@Prop({ default: 'value' }) public optionValue!: string | null;
	@Prop({ default: null }) public firstOptionValue!: any;
	@Prop({ default: true }) public isEnableFilter!: boolean;
	@Prop({ default: true }) public isEnableClear!: boolean;

	public selected: string = this.value;

	@Watch('value')
	public onValueChange(newVal: string, oldVal: string) {
		this.selected = newVal;
	}
	@Watch('selected')
	public onSelectedChange(newVal: string, oldVal: string) {
		if (this.selected !== this.value) {
			this.$emit('input', this.selected);
		}
	}
}
