
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import BasicTreeMap from '@/components/reports-v2/components/elements/charts/BasicTreeMap.vue';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import { TooltipFormatter } from '../elements/charts/helpers/tooltipFormatter';
import BaseFilterableContainer from './BaseFilterableContainer.vue';
import BaseFilterable from './BaseFilterable.vue';
import { GobiColor } from '../../../../helpers/color';
import { DataViewFormatter } from '../elements/charts/helpers/dataViewFormatter';

@Component({
	components: {
		BasicTreeMap,
		DataInfo,
		BaseFilterable,
	},
})
export default class TreeChartFilterable extends BaseFilterableContainer {
	// Formatter
	@Prop({ default: () => TooltipFormatter.basicTreeMap })
	public readonly tooltipFormatter!: any;
	@Prop({ default: () => DataViewFormatter.treemap })
	public readonly dataViewFormatter!: any;
	@Prop({})
	public readonly legends!: string[];
	@Prop({
		default: () => [
			'#8975ea',
			'#acbeff',
			'#accdff',
			'#acdaff',
			'#cdc8f9',
			'#c7b5e2',
		],
	})
	public readonly color!: string[];
	@Prop({ default: () => [] })
	public readonly series!: echarts.ECharts;

	public get printedTitle() {
		return `${this.title} - ${this.dateFormatted}`;
	}
}
