
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TreeMapFilterable from '@/components/reports-v2/components/filterables/TreeMapFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	CustomerSupplierDbCard,
	DimensionData,
	PermissionsGroup,
	ValueText,
} from '@/store/models.def';
import * as echarts from 'echarts';

@Component({
	components: {
		TreeMapFilterable,
		SelectFilter,
	},
})
export default class SupplierAnalysisTreeMap extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['suppliers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public result: any = [];
	public series: echarts.ECharts[] | any = [];

	public selectedGrouping: any = null;
	public bigSupplierInput: number = 15000;
	public smallSupplierInput: number = 8000;
	public isCalculateGrouping: number = 0;

	public generateGrouping() {
		this.isCalculateGrouping += +1;
	}

	public get groupingList(): Array<ValueText<string>> {
		const list = [
			// { text: 'None', value: 'none' },
			{ text: 'Supplier', value: 'supplier' },
			{ text: 'Stock Items', value: 'stockItems' },
			{ text: 'Stock Group', value: 'stockGroup' },
			// { text: 'Company Categories', value: 'customerCategory' },
			// { text: 'Sales Agent', value: 'salesAgent' },
			// { text: 'Area', value: 'area' },
			// { text: 'Current vs New ', value: 'currentNew' },
			{ text: 'Supplier Size', value: 'supplierSize' },
			// { text: 'Top Customer', value: 'topCustomer' },
			// { text: 'New Supplier', value: 'newSupplier' },
			// { text: 'Current Supplier', value: 'currentSupplier' },
		];

		return list;
	}

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedSuppliers,
			selectedGrouping,
			isCalculateGrouping,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedSuppliers,
			selectedGrouping,
			isCalculateGrouping,
		]);
	}

	public async expensiveCalc() {
		const purchaseRef = FilteredDatabase.ref('purchases').dateRange(
			this.selectedDateRange,
		);

		const cnRef = FilteredDatabase.ref('creditNotes').dateRange(
			this.selectedDateRange,
		);

		let tradePisdRef = FilteredDatabase.ref('supplierTrades')
			.dateRange(this.selectedDateRange)
			.includes('docType', ['PI', 'SD']);

		const salesAmount = await (await tradePisdRef.get()).getSum('amount');
		const salesCount = await (await tradePisdRef.get()).getCount();
		const salesAmountMinMax = await (
			await tradePisdRef.get()
		).getMinMax('amount');

		if (this.isCalculateGrouping === 0) {
			this.smallSupplierInput = Math.round(salesAmount / salesCount);
			this.bigSupplierInput = Math.round(salesAmountMinMax.max / 2);
		}

		const supplierList = [...(await (await tradePisdRef.get()).getSet('code'))];

		const agentList = [...(await (await tradePisdRef.get()).getSet('agent'))];

		const newRef = FilteredDatabase.ref('globalSuppliers')
			.suppliers(supplierList)
			.numberRange('firstDeal', [
				['>=', this.selectedDateRange[0]],
				['<=', this.selectedDateRange[1]],
			]);

		const newSupplierList = [...(await (await newRef.get()).getSet('code'))];

		const existingRef = FilteredDatabase.ref('globalSuppliers')
			.suppliers(supplierList)
			.numberRange('firstDeal', [
				['>', Number.NEGATIVE_INFINITY],
				['<', this.selectedDateRange[0]],
			]);

		const existingSupplierList = [
			...(await (await existingRef.get()).getSet('code')),
		];

		const globalRef =
			FilteredDatabase.ref('globalSuppliers').suppliers(supplierList);

		const globalCards: CustomerSupplierDbCard[] = await (
			await globalRef.get()
		).getCards();

		const supplierAreaList = {};

		for (const global of globalCards) {
			if (!supplierAreaList[global.areaDesc]) {
				supplierAreaList[global.areaDesc] = [];
			}
			supplierAreaList[global.areaDesc].push(global.code);
		}

		tradePisdRef = FilteredDatabase.ref('supplierTrades')
			.dateRange(this.selectedDateRange)
			.agents(agentList)
			.suppliers(supplierList)
			.includes('docType', ['PI', 'SD']);

		const tradeCNRef = FilteredDatabase.ref('supplierTrades')
			.dateRange(this.selectedDateRange)
			.agents(agentList)
			.suppliers(supplierList)
			.includes('docType', ['SC']);

		const newTradePisdRef = FilteredDatabase.ref('supplierTrades')
			.dateRange(this.selectedDateRange)
			.suppliers(newSupplierList)
			.includes('docType', ['PI', 'SD']);

		const newTradeCNRef = FilteredDatabase.ref('supplierTrades')
			.dateRange(this.selectedDateRange)
			.suppliers(newSupplierList)
			.includes('docType', ['SC']);

		const existingTradePisdRef = FilteredDatabase.ref('supplierTrades')
			.dateRange(this.selectedDateRange)
			.suppliers(existingSupplierList)
			.includes('docType', ['PI', 'SD']);

		const existingTradeCNRef = FilteredDatabase.ref('supplierTrades')
			.dateRange(this.selectedDateRange)
			.suppliers(existingSupplierList)
			.includes('docType', ['SC']);

		let tradeDD: DimensionData[] = [];
		let tradeCNDD: DimensionData[] = [];

		let pa = 0.16;
		let pb = 0;

		const newSupplierTradeIvdnDD = await this._loadDimensionByFilters(
			newTradePisdRef,
			'suppliers',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.16;

		const newSupplierTradeCNDD = await this._loadDimensionByFilters(
			newTradeCNRef,
			'suppliers',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.16;

		const existingSupplierTradeIvdnDD = await this._loadDimensionByFilters(
			existingTradePisdRef,
			'suppliers',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.16;

		const existingCustomerTradeCNDD = await this._loadDimensionByFilters(
			existingTradeCNRef,
			'suppliers',
			pa,
			pb,
			'amount',
		);

		switch (this.selectedGrouping) {
			case 'stockGroup':
				pb += pa;
				pa = 0.16;

				tradeDD = await this._loadDimensionByFilters(
					purchaseRef,
					'stockGroup',
					pa,
					pb,
					(reff, paa, pbb) =>
						this._loadDimensionByFilters(
							reff,
							'stockItems',
							paa,
							pbb,
							'amount',
						),
				);
				tradeCNDD = await this._loadDimensionByFilters(
					cnRef,
					'stockGroup',
					pa,
					pb,
					(reff, paa, pbb) =>
						this._loadDimensionByFilters(
							reff,
							'stockItems',
							paa,
							pbb,
							'amount',
						),
				);
				break;
			case 'stockItems':
				pb += pa;
				pa = 0.16;

				tradeDD = await this._loadDimensionByFilters(
					purchaseRef,
					'stockItems',
					pa,
					pb,
					'amount',
				);
				tradeCNDD = await this._loadDimensionByFilters(
					cnRef,
					'stockItems',
					pa,
					pb,
					'amount',
				);
				break;
			case 'customerCategory':
				pb += pa;
				pa = 0.16;

				tradeDD = await this._loadDimensionByFilters(
					tradePisdRef,
					'customerCategory',
					pa,
					pb,
					(reff, paa, pbb) =>
						this._loadDimensionByFilters(reff, 'suppliers', paa, pbb, 'amount'),
				);

				pb += pa;
				pa = 0.16;

				tradeCNDD = await this._loadDimensionByFilters(
					tradeCNRef,
					'customerCategory',
					pa,
					pb,
					(reff, paa, pbb) =>
						this._loadDimensionByFilters(reff, 'suppliers', paa, pbb, 'amount'),
				);
				break;
			case 'salesAgent':
				pb += pa;
				pa = 0.16;

				tradeDD = await this._loadDimensionByFilters(
					tradePisdRef,
					'agents',
					pa,
					pb,
					(reff, paa, pbb) =>
						this._loadDimensionByFilters(reff, 'suppliers', paa, pbb, 'amount'),
				);

				pb += pa;
				pa = 0.16;

				tradeCNDD = await this._loadDimensionByFilters(
					tradeCNRef,
					'agents',
					pa,
					pb,
					(reff, paa, pbb) =>
						this._loadDimensionByFilters(reff, 'suppliers', paa, pbb, 'amount'),
				);
				break;
			case 'newSupplier':
				pb += pa;
				pa = 0.16;

				tradeDD = newSupplierTradeIvdnDD;

				pb += pa;
				pa = 0.16;

				tradeCNDD = newSupplierTradeCNDD;
				break;
			case 'currentSupplier':
				pb += pa;
				pa = 0.16;

				tradeDD = existingSupplierTradeIvdnDD;

				pb += pa;
				pa = 0.16;

				tradeCNDD = existingCustomerTradeCNDD;
				break;
			case 'supplierSize':
				pb += pa;
				pa = 0.16;

				tradeDD = await this._loadDimensionByFilters(
					tradePisdRef,
					'suppliers',
					pa,
					pb,
					'amount',
				);

				pb += pa;
				pa = 0.16;

				tradeCNDD = await this._loadDimensionByFilters(
					tradeCNRef,
					'suppliers',
					pa,
					pb,
					'amount',
				);
				break;
			default:
				pb += pa;
				pa = 0.16;

				tradeDD = await this._loadDimensionByFilters(
					tradePisdRef,
					'suppliers',
					pa,
					pb,
					'amount',
				);
				pb += pa;
				pa = 0.16;

				tradeCNDD = await this._loadDimensionByFilters(
					tradeCNRef,
					'suppliers',
					pa,
					pb,
					'amount',
				);
		}
		this.generateLoadingText(1);

		this.result = [];

		if (this.selectedGrouping === 'currentNew') {
			this.result.push({
				name: 'New Supplier',
				value:
					newSupplierTradeIvdnDD
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0) -
					newSupplierTradeCNDD
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0),
				color: [
					'#8975ea',
					'#acbeff',
					'#accdff',
					'#acdaff',
					'#cdc8f9',
					'#c7b5e2',
				],
				colorSaturation: [0.9, 0.4],
				children: newSupplierTradeIvdnDD.map((supplier, index) => ({
					name: supplier.text,
					value: supplier.sum - newSupplierTradeCNDD[index].sum,
					color: [
						'#8975ea',
						'#acbeff',
						'#accdff',
						'#acdaff',
						'#cdc8f9',
						'#c7b5e2',
					],
					colorSaturation: [0.9, 0.4],
				})),
			});
			this.result.push({
				name: 'Existing Supplier',
				value:
					existingSupplierTradeIvdnDD
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0) -
					existingCustomerTradeCNDD
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0),
				color: [
					'#8975ea',
					'#acbeff',
					'#accdff',
					'#acdaff',
					'#cdc8f9',
					'#c7b5e2',
				],
				colorSaturation: [0.9, 0.4],
				children: existingSupplierTradeIvdnDD.map((supplier, index) => ({
					name: supplier.text,
					value: supplier.sum - existingCustomerTradeCNDD[index].sum,
					color: [
						'#8975ea',
						'#acbeff',
						'#accdff',
						'#acdaff',
						'#cdc8f9',
						'#c7b5e2',
					],
					colorSaturation: [0.9, 0.4],
				})),
			});
		} else if (this.selectedGrouping === 'supplierSize') {
			this.result.push({
				name: 'Above ' + String(this.bigSupplierInput),
				value:
					tradeDD
						.filter((dd) => dd.sum > this.bigSupplierInput)
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0) -
					tradeCNDD
						.filter((dd) => dd.sum > this.bigSupplierInput)
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0),
				color: [
					'#8975ea',
					'#acbeff',
					'#accdff',
					'#acdaff',
					'#cdc8f9',
					'#c7b5e2',
				],
				colorSaturation: [0.9, 0.4],
				children: tradeDD
					.filter((dd) => dd.sum > this.bigSupplierInput)
					.map((supplier) => {
						const object = {
							name: supplier.text,
							value: 0,
							color: [
								'#8975ea',
								'#acbeff',
								'#accdff',
								'#acdaff',
								'#cdc8f9',
								'#c7b5e2',
							],
							colorSaturation: [0.9, 0.4],
						};
						const cnIndex = tradeCNDD.findIndex(
							(cn) => cn.value[0] === supplier.value[0],
						);

						object.value =
							supplier.sum -
							(tradeCNDD[cnIndex].sum ? tradeCNDD[cnIndex].sum : 0);

						return object;
					}),
			});
			this.result.push({
				name:
					'Between ' +
					String(this.smallSupplierInput) +
					' and ' +
					String(this.bigSupplierInput),
				value:
					tradeDD
						.filter(
							(dd) =>
								dd.sum >= this.smallSupplierInput &&
								dd.sum <= this.bigSupplierInput,
						)
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0) -
					tradeCNDD
						.filter(
							(dd) =>
								dd.sum >= this.smallSupplierInput &&
								dd.sum <= this.bigSupplierInput,
						)
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0),
				color: [
					'#8975ea',
					'#acbeff',
					'#accdff',
					'#acdaff',
					'#cdc8f9',
					'#c7b5e2',
				],
				colorSaturation: [0.9, 0.4],
				children: tradeDD
					.filter(
						(dd) =>
							dd.sum >= this.smallSupplierInput &&
							dd.sum <= this.bigSupplierInput,
					)
					.map((supplier) => {
						const object = {
							name: supplier.text,
							value: 0,
							color: [
								'#8975ea',
								'#acbeff',
								'#accdff',
								'#acdaff',
								'#cdc8f9',
								'#c7b5e2',
							],
							colorSaturation: [0.9, 0.4],
						};

						const cnIndex = tradeCNDD.findIndex(
							(cn) => cn.value[0] === supplier.value[0],
						);

						object.value =
							supplier.sum -
							(tradeCNDD[cnIndex].sum ? tradeCNDD[cnIndex].sum : 0);

						return object;
					}),
			});
			this.result.push({
				name: 'Below ' + String(this.smallSupplierInput),
				value:
					tradeDD
						.filter((dd) => dd.sum < this.smallSupplierInput)
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0) -
					tradeCNDD
						.filter((dd) => dd.sum < this.smallSupplierInput)
						.map((item) => item.sum)
						.reduce((a, b) => a + b, 0),
				color: [
					'#8975ea',
					'#acbeff',
					'#accdff',
					'#acdaff',
					'#cdc8f9',
					'#c7b5e2',
				],
				colorSaturation: [0.9, 0.4],
				children: tradeDD
					.filter((dd) => dd.sum < this.smallSupplierInput)
					.map((supplier) => {
						const object = {
							name: supplier.text,
							value: 0,
							color: [
								'#8975ea',
								'#acbeff',
								'#accdff',
								'#acdaff',
								'#cdc8f9',
								'#c7b5e2',
							],
							colorSaturation: [0.9, 0.4],
						};

						const cnIndex = tradeCNDD.findIndex(
							(cn) => cn.value[0] === supplier.value[0],
						);

						object.value =
							supplier.sum -
							(tradeCNDD[cnIndex].sum ? tradeCNDD[cnIndex].sum : 0);

						return object;
					}),
			});
		} else if (this.selectedGrouping === 'area') {
			const areaList = Object.keys(supplierAreaList);
			const codeList: string[][] = Object.values(supplierAreaList);

			areaList.forEach((area, index) => {
				const codes: string[] = codeList[index];
				const object = {
					name: area,
					value: 0,
					color: [
						'#8975ea',
						'#acbeff',
						'#accdff',
						'#acdaff',
						'#cdc8f9',
						'#c7b5e2',
					],
					colorSaturation: [0.9, 0.4],
					children: [] as Array<{
						name: string;
						value: number;
						color: string[];
					}>,
				};

				codes.forEach((code) => {
					const ivdn = tradeDD.find((dd) => dd.value[0] === code);
					const cn = tradeCNDD.find((dd) => dd.value[0] === code);
					if (ivdn && cn) {
						object.children.push({
							name: ivdn.text,
							value: ivdn.sum - cn.sum,
							color: [
								'#8975ea',
								'#acbeff',
								'#accdff',
								'#acdaff',
								'#cdc8f9',
								'#c7b5e2',
							],
						});
					}
					if (ivdn) {
						object.children.push({
							name: ivdn.text,
							value: ivdn.sum,
							color: [
								'#8975ea',
								'#acbeff',
								'#accdff',
								'#acdaff',
								'#cdc8f9',
								'#c7b5e2',
							],
						});
					}
				});
				object.value = object.children
					.map((item) => item.value)
					.reduce((a, b) => a + b, 0);
				this.result.push(object);
			});
		} else {
			tradeDD.forEach((trade, index) => {
				const subDD = trade.subDimension;
				const cnSubDD = tradeCNDD[index].subDimension;
				this.result.push({
					name: trade.text,
					value: trade.sum,
					color: [
						'#8975ea',
						'#acbeff',
						'#accdff',
						'#acdaff',
						'#cdc8f9',
						'#c7b5e2',
					],
					colorSaturation: [0.9, 0.4],
					children:
						subDD && cnSubDD
							? subDD.map((subdd, subIndex) => ({
									name: subdd.text,
									value: subdd.sum - cnSubDD[subIndex].sum,
									color: [
										'#8975ea',
										'#acbeff',
										'#accdff',
										'#acdaff',
										'#cdc8f9',
										'#c7b5e2',
									],
									colorSaturation: [0.9, 0.4],
							  }))
							: subDD
							? subDD.map((subdd) => ({
									name: subdd.text,
									value: subdd.sum,
									color: [
										'#8975ea',
										'#acbeff',
										'#accdff',
										'#acdaff',
										'#cdc8f9',
										'#c7b5e2',
									],
									colorSaturation: [0.9, 0.4],
							  }))
							: [],
				});
			});
		}

		this.result = this.result.filter((item) => item.value > 0);

		this.series = [];

		const stock = {
			name: 'Supplier Analysis',
			type: 'treemap',
			roam: 'move',
			width: '100%',
			// drillDownIcon: '👆🏻',
			drillDownIcon: '',
			// leafDepth: 1,
			label: {
				color: '#000',
			},
			itemStyle: {
				gapWidth: 1,
				borderWidth: 1,
			},
			levels: [
				{
					itemStyle: {
						// borderColor: '#555',
						borderWidth: 2,
						gapWidth: 2,
					},
					upperLabel: {
						show: false,
						fontWeight: 'bold',
						color: '#000',
					},
					emphasis: {
						itemStyle: {
							borderWidth: 0,
							gapWidth: 2,
						},
						upperLabel: {
							show: true,
							fontWeight: 'bold',
							color: '#000',
						},
					},
				},
				{
					colorSaturation: [0.25, 0.6],
					itemStyle: {
						borderColorSaturation: 0.7,
						gapWidth: 2,
						borderWidth: 2,
					},
					upperLabel: {
						show: true,
						color: '#000',
						fontWeight: 'bold',
					},
					emphasis: {
						itemStyle: {
							borderWidth: 0,
							gapWidth: 1,
						},
						upperLabel: {
							show: true,
							color: '#000',
							fontWeight: 'bold',
						},
					},
				},
				{
					itemStyle: {
						// borderColor: '#555',
						gapWidth: 1,
						borderWidth: 1,
					},
					upperLabel: {
						show: true,
						color: '#000',
						fontWeight: 'bold',
					},
					emphasis: {
						itemStyle: {
							borderWidth: 0,
							gapWidth: 1,
						},
						upperLabel: {
							show: true,
							color: '#000',
							fontWeight: 'bold',
						},
					},
				},
			],
			data: this.result,
		};

		this.series = [stock];

		this.saveHistory(
			'result',
			'series',
			'bigSupplierInput',
			'smallSupplierInput',
		);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
