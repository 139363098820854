
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts/core';
import { TreemapChart, TreemapSeriesOption } from 'echarts/charts';
import {
	ToolboxComponent,
	ToolboxComponentOption,
	TooltipComponent,
	TooltipComponentOption,
	TitleComponent,
	TitleComponentOption,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { GobiColor } from '@/helpers/color';
import resize from 'vue-resize-directive';
import uuidv1 from 'uuid/v1';
import { DataZoomList } from '@/components/reports-v2/components/elements/charts/helpers/dataZoom';
import { TooltipFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tooltipFormatter';
import { DataViewFormatter } from './helpers/dataViewFormatter';

echarts.use([
	TreemapChart,
	ToolboxComponent,
	TooltipComponent,
	TitleComponent,
	CanvasRenderer,
]);

type ECOption = echarts.ComposeOption<
	| TreemapSeriesOption
	| ToolboxComponentOption
	| TooltipComponentOption
	| TitleComponentOption
>;

@Component({
	directives: {
		resize,
	},
})
export default class BasicTreeMap extends Vue {
	@Prop({
		default: () => [
			'#8975ea',
			'#acbeff',
			'#accdff',
			'#acdaff',
			'#cdc8f9',
			'#c7b5e2',
		],
	})
	public readonly color!: string[];
	@Prop({ default: () => [] })
	public readonly series!: echarts.EChartsCoreOption;
	@Prop({}) public readonly legends!: ECOption;
	@Prop({ default: 'Title' }) public readonly title!: string;
	@Prop({ default: () => TooltipFormatter.basic })
	public readonly tooltipFormatter!: any;
	@Prop({ default: () => DataViewFormatter.treemap })
	public readonly dataViewFormatter!: any;

	public mainChart: echarts.ECharts | null = null;
	public elId: string = '';

	public created() {
		this.elId = uuidv1();
	}

	public mounted() {
		this.initChart();
	}

	public initChart() {
		const myChart: any = document.getElementById(this.elId);

		const mainChart = (this.mainChart = echarts.init(myChart));
		this.updateChart();
	}

	@Watch('series')
	public onSeriesChange(newVal, oldVal) {
		this.updateChart();
	}

	public updateChart() {
		if (!this.mainChart) {
			return;
		}
		const option: echarts.EChartsCoreOption = {
			darkMode: 'auto',
			color: this.color,
			toolbox: {
				feature: {
					saveAsImage: {
						name: this.title,
						title: 'Save as image',
						iconStyle: {
							borderColor: GobiColor.TEAL,
						},
					},
					dataView: {
						show: true,
						readOnly: true,
						title: 'Chart data',
						lang: ['', 'Close', ''],
						iconStyle: {
							borderColor: GobiColor.TEAL,
						},
						optionToContent: this.dataViewFormatter,
					},
				},
			},
			tooltip: {
				confine: true,
				trigger: 'item',
				formatter: this.tooltipFormatter,
			},
			stateAnimation: {
				duration: 300,
				easing: 'cubicOut',
			},
			animation: true,
			animationDuration: 1000,
			animationDurationUpdate: 500,
			animationEasing: 'cubicInOut',
			animationEasingUpdate: 'cubicInOut',
			animationThreshold: 2000,
			progressiveThreshold: 3000,
			progressive: 400,
			hoverLayerThreshold: 3000,
			series: this.series,
		};
		this.mainChart.setOption(option, true);
	}

	public onResize() {
		if (this.mainChart) {
			this.mainChart.resize();
		}
	}
}
